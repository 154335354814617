/**
 * Basic UI: Popup.
 */
const BasicPopup = (() => {

	class Core {

	    constructor({trigger, popup, content}) {
			
			this.$trigger = trigger || null;
			
			this.$popup = {
				el: 	 popup   || null,
				content: content || ''
			};		

			this.$options = {
				className: {
					main:  	 'popup',
					show: 	 'popup--show',
					close: 	 'popup-close',
					content: 'popup-content'
				}
			};

			this.$customEventToggle = new CustomEvent('popup toggle', { 
				bubbles: true,
				detail:  {
					'popup': null,
					'trigger': null,
					'hidden': false
				}
			});
			
			
			this.build();
			this.events();
	    	
	    }

		
		build() {

			let popupModal = null;


	    	if (this.$popup.el !== null && this.$popup.el.classList.contains('popup-init') == false) {
				
				popupModal = document.createElement('div');
				popupModal.classList.add('popup-modal');
				popupModal.setAttribute('role', 'dialog');
				popupModal.setAttribute('aria-modal', 'true');
				popupModal.innerHTML = `
					<button 
						class="${this.$options.className.close}" 
						type="button" 
						title="Close popup" 
						aria-label="Close popup"
					>
						<svg width="512" height="512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
							<path d="M49.0982 4.74249L512 476.61L477.282 512L14.3805 40.1325L49.0982 4.74249Z"/>
							<path d="M0 471.868L462.902 0L497.62 35.3901L34.7176 507.258L0 471.868Z"/>
						</svg>
					</button>
					<div class="${this.$options.className.content}"></div>
				`;
				
				while (this.$popup.el.firstChild) {

					popupModal.querySelector(`.${this.$options.className.content}`).appendChild(this.$popup.el.firstChild);

				}
				
				this.$popup.el.classList.add(this.$options.className.main);
				this.$popup.el.classList.add('popup-init');
				this.$popup.el.appendChild(popupModal);

	    	}
		
	    }


		events() {

			if (this.$trigger !== null)  this.$trigger.addEventListener('click', this.listener_trigger.bind(this), false);
			if (this.$popup.el !== null) this.$popup.el.addEventListener('click', this.listener_popup.bind(this), false);

		}


		listener_trigger() {

			this.method_show();

	    	// custom event: popup toggle
			this.$customEventToggle.detail.hidden = false;
			this.$customEventToggle.detail.trigger = this.$trigger;
			this.$customEventToggle.detail.popup = this.$popup.el;
			this.$popup.el.dispatchEvent(this.$customEventToggle);	

	    }

	
		listener_popup(event) {

	    	let target = event.target;
			
			if (target.classList.contains(this.$options.className.main) == true || target.closest(`.${this.$options.className.close}`) !== null) {

				this.method_hide();				

			}

	    	// custom event: popup toggle
			this.$customEventToggle.detail.hidden = true;
			this.$customEventToggle.detail.popup = this.$popup.el;
			this.$customEventToggle.detail.trigger = this.$trigger;
			this.$popup.el.dispatchEvent(this.$customEventToggle);	

	    }


		method_show() {
						
			document.body.classList.add('body-popup');
	    	this.$popup.el.classList.add(this.$options.className.show);

	    }


	    method_hide() {

			document.body.classList.remove('body-popup');
			this.$popup.el.classList.remove(this.$options.className.show);

	    }


		method_html() {

			this.$popup.el.querySelector(`.${this.$options.className.content}`).innerHTML = this.$popup.content;
			this.method_show();

		}

	}


	let active = null;


	/**
	 * Popup initialization
	 * @param {(Object|string)} config.trigger - trigger element
	 * @param {string} 			config.popup   - popup element
	 */
	const init = (config) => {

		let options = config || {},
			elements = BasicCore.variables(options.trigger, '.js-trigger-popup');


		try {

			if (elements == false && options.trigger !== undefined) throw new Error(BasicCore.logging['error']['popup']['missing']['trigger']);
			if (elements == null  && options.trigger !== undefined) throw new Error(BasicCore.logging['error']['type']);			
							
			elements.forEach((value) => {

				options.trigger = value;
				options.popup = document.querySelector(`#${value.getAttribute('data-popup-id')}`) || document.querySelector(options.popup);
				
				active = new Core(options);
			
			});
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Popup init. \nMessage: ${error.message} \nElement: `, options.trigger);

		}

	};


	/**
	 * Method: Show a popup
	 * @param {(Object|string)} popup - show a popup element
	 */
	const show = (popup) => {

		let options = {
			popup: (typeof popup == 'object') ? popup : document.querySelector(popup)
		};
				

		try {

			if (options.popup == null) throw new Error(BasicCore.logging['error']['popup']['missing']['modal']);
			
			active = new Core(options);
			active.method_show();
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Popup show. \nMessage: ${error.message} \nElement:`, popup);

		}	

	};	


	/**
	 * Method: Hide a popup
	 * @param {(Object|string)} popup - hide a popup element
	 */
	const hide = (popup) => {

		let options = {
			popup: (typeof popup == 'object') ? popup : document.querySelector(popup)
		};	

		
		try {

			if (options.popup == null) throw new Error(BasicCore.logging['error']['popup']['missing']['modal']);
			
			active = new Core(options);
			active.method_hide();

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Popup hide. \nMessage: ${error.message} \nElement:`, popup);

		}	

	};


	/**
	 * Method: add dynamic content in popup
	 * @param {(Object|string)} popup   - popup element
	 * @param {(String)} 		content - popup content
	 */
	const html = (config) => {

		let options = config || {};	

		options.popup = (typeof options.popup == 'object') ? options.popup : document.querySelector(options.popup);
		
		try {

			if (options.popup == null) throw new Error(BasicCore.logging['error']['popup']['missing']['modal']);
			
			active = new Core(options);
			active.method_html();

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Popup html. \nMessage: ${error.message} \nElement:`, config.popup);
		
		}	

	};


	return { init, show, hide, html };

})()
	

window.BasicPopup = BasicPopup;


export { BasicPopup };