const OthersScrollView = (() => {


    class Core {

        constructor(el) {

            this.$el = el;
            this.$scrollElement = document.querySelector(this.$el.getAttribute('data-scroll-element'));

            this.$el.classList.add('scroll-view-init');
            this.$el.addEventListener('click', this.listener_click.bind(this), false);

        }


        listener_click(event) {            

            window.scrollTo({top: this.$scrollElement.offsetTop, behavior: "smooth"});
            event.preventDefault();

        }

    }


    let active = null;


    const init = () => {

        let elements = Array.from(document.querySelectorAll('.js-scroll-view'));


        elements.forEach((value) => active = new Core(value));
        return active;

    }


    return {
        init
    };

})();


export {
    OthersScrollView
};