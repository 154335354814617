// polyfills
import './modules/polyfills/find.js';
import './modules/polyfills/array.from.js';
import './modules/polyfills/closest.js';
import './modules/polyfills/number.parseInt.js';
import './modules/polyfills/startsWith.js';
import './modules/polyfills/customEvent.js';
// import './modules/polyfills/smoothscroll';

// basic ui
import './modules/basic-ui/core.js';
import { BasicTabs } 		   from './modules/basic-ui/ui/tabs.js';
import { BasicPopup } 		   from './modules/basic-ui/ui/popup.js';
import { BasicMask } 		   from './modules/basic-ui/ui/mask/mask.js';
import { BasicSlider } 		   from './modules/basic-ui/ui/slider/slider.js';
import { BasicSlideout } 	   from './modules/basic-ui/ui/slideout.js';
import { BasicReadmore } 	   from './modules/basic-ui/ui/readmore.js';
import { BasicLazyLoadingImg } from './modules/basic-ui/ui/lazy-loading-img.js';

// sections
import { SectionTeam } from './modules/sections/team.js';
import './modules/sections/reputation.js';
// import { SectionSkills } from './modules/sections/skills.js';

// others
import { OthersAnimation }  from './modules/others/animation/animation.js';
import { OthersScrollView } from './modules/others/scroll-view.js';
import { OthersMap } 	    from './modules/others/map.js';
import './modules/calculator/calc.js'


// init
document.addEventListener('DOMContentLoaded', () => {

	// basic ui
	BasicTabs.init();
	BasicPopup.init();
	BasicMask.init();
	BasicSlider.init();
	BasicSlideout.init();
	BasicReadmore.init();
	BasicLazyLoadingImg.init();

	// section
	SectionTeam.init();
	// SectionSkills.init();
	
	// others
	OthersAnimation.init();
	OthersScrollView.init();
	OthersMap.init();
		
});