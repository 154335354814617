/**
 * Basic UI: Tabs.
 */
const BasicTabs = (() => {

    class Core {
        constructor(element) {
            this.$el = element;

            this.$customEvent = new CustomEvent('tabs choice', {
                bubbles: true,
                detail: {
                    'index': null,
                    'trigger': null,
                    'content': null
                }
            });

            this.build();
            this.refer();
            this.event();
        }


        build() {
            let list = [],
                elIndex = null, elNav = null, elMain = null;

            list = Array.from(document.querySelectorAll('.tab-init'));
            list.push(this.$el);

            elIndex = list.indexOf(this.$el);
            elNav = this.$el.querySelector('.tab-nav');
            elMain = this.$el.querySelector('.tab-main');

            this.$triggers = Array.from(elNav.getElementsByClassName('tab-trigger'));
            this.$contents = Array.from(elMain.getElementsByClassName('tab-content'));

            this.$el.classList.add('tab');
            this.$el.classList.add('tab-init');
            elNav.setAttribute('role', 'tablist');

            // build trigger
            this.$triggers.forEach((trigger, index) => {

                trigger.setAttribute('role', 'tab');
                trigger.setAttribute('id', `tab-${elIndex}-trigger-${index}`);

                if (trigger.getAttribute('aria-selected') == 'true') {

                    trigger.classList.add('tab-trigger--selected');

                    this.$contents[index].setAttribute('tabindex', 0);
                    this.$contents[index].setAttribute('aria-selected', true);
                    this.$contents[index].classList.add('tab-content--selected');

                } else {
                    trigger.setAttribute('tabindex', -1);
                    trigger.setAttribute('aria-selected', false);
                }

            })


            // build content
            this.$contents.forEach((content, index) => {

                content.setAttribute('role', 'tabpanel');
                content.setAttribute('aria-labelledby', `tab-${elIndex}-trigger-${index}`);

                // selected
                if (content.getAttribute('aria-selected') !== 'true') {

                    content.setAttribute('tabindex', -1);
                    content.setAttribute('aria-selected', false);
                }

                // id
                if (content.getAttribute('id')) {
                    this.$triggers[index].setAttribute('aria-controls', content.getAttribute('id'));
                }
				else {
                    content.setAttribute('id', `tab-${elIndex}-content-${index}`);
                    this.$triggers[index].setAttribute('aria-controls', `tab-${elIndex}-content-${index}`);
                }
            })


            // init selected
            if (this.$el.querySelector('.tab-trigger--selected') == null && this.$el.querySelector('.tab-content--selected') == null) {

                this.$triggers[0].setAttribute('tabindex', 0);
                this.$triggers[0].setAttribute('aria-selected', true);
                this.$triggers[0].classList.add('tab-trigger--selected');

                this.$contents[0].setAttribute('aria-selected', true);
                this.$contents[0].classList.add('tab-content--selected');
            }
        }


        refer() {

            if (location.hash !== '') {

                let selected = {
                    trigger: this.$el.querySelector('.tab-trigger.tab-trigger--selected'),
                    content: this.$el.querySelector('.tab-content.tab-content--selected')
                };


                this.$contents.forEach((content, index) => {

                    let idContent = `#${content.getAttribute('id')}`;


                    if (idContent == location.hash) {

                        // selected
                        selected.trigger.setAttribute('tabindex', -1);
                        selected.trigger.setAttribute('aria-selected', false);
                        selected.trigger.classList.remove('tab-trigger--selected');
                        selected.content.setAttribute('tabindex', -1);
                        selected.content.classList.remove('tab-content--selected');


                        if (this.$triggers[index]) {

                            this.$triggers[index].classList.add('tab-trigger--selected');
                            this.$triggers[index].setAttribute('tabindex', 0);
                            this.$triggers[index].setAttribute('aria-selected', true);

                        }


                        content.setAttribute('tabindex', 0);
                        content.classList.add('tab-content--selected');

                    }

                })

            }

        }


        event() {

            this.$el.addEventListener('click', this.listener_click.bind(this), false);
            this.$el.addEventListener('keydown', this.listener_keydown.bind(this), false);

        }


        listener_click(event) {

            let target = event.target,
                trigger = target.closest('.tab-trigger:not(.tab-trigger--selected)') || false;


            if (trigger !== false) {

                let current = {
                        index: this.$triggers.indexOf(trigger),
                        trigger: trigger,
                        content: this.$contents[this.$triggers.indexOf(trigger)]
                    },

                    selected = {
                        trigger: this.$el.querySelector('.tab-trigger.tab-trigger--selected') || this.$triggers[0],
                        content: this.$el.querySelector('.tab-content.tab-content--selected') || this.$contents[0]
                    };


                // selected
                selected.trigger.setAttribute('tabindex', -1);
                selected.trigger.setAttribute('aria-selected', false);
                selected.trigger.classList.remove('tab-trigger--selected');

                if (selected.content) {
                    selected.content.setAttribute('tabindex', -1);
                    selected.content.classList.remove('tab-content--selected');
                }


                // current
                current.trigger.setAttribute('tabindex', 0);
                current.trigger.setAttribute('aria-selected', true);
                current.trigger.classList.add('tab-trigger--selected');

                if (current.content) {

                    current.content.setAttribute('tabindex', 0);
                    current.content.classList.add('tab-content--selected');

                }


                // custom event: tabs choice
                this.$customEvent.detail.index = current.index;
                this.$customEvent.detail.trigger = current.trigger;
                this.$customEvent.detail.content = current.content;
                this.$el.dispatchEvent(this.$customEvent);

            }

        }


        listener_keydown(event) {

            let code = event.code;


            // ArrowLeft and ArrowRight
            if (code == 'ArrowLeft' || code == 'ArrowRight') {

                let current = {
                        index: false,
                        trigger: false,
                        content: false
                    },

                    selected = {
                        trigger: this.$el.querySelector('.tab-trigger.tab-trigger--selected') || this.$triggers[0],
                        content: this.$el.querySelector('.tab-content.tab-content--selected') || this.$contents[0]
                    };


                // selected
                selected.trigger.setAttribute('tabindex', -1);
                selected.trigger.setAttribute('aria-selected', false);
                selected.trigger.classList.remove('tab-trigger--selected');
                selected.content.setAttribute('tabindex', -1);
                selected.content.classList.remove('tab-content--selected');


                if (code == 'ArrowRight') current.trigger = (selected.trigger.nextElementSibling !== null) ? selected.trigger.nextElementSibling : this.$triggers[0];
                if (code == 'ArrowLeft') current.trigger = (selected.trigger.previousElementSibling !== null) ? selected.trigger.previousElementSibling : this.$triggers[this.$triggers.length - 1];


                // current
                current.trigger.focus();
                current.trigger.setAttribute('tabindex', 0);
                current.trigger.setAttribute('aria-selected', true);
                current.trigger.classList.add('tab-trigger--selected');
                current.index = this.$triggers.indexOf(current.trigger);
                current.content = this.$contents[current.index];

                if (current.content != undefined) {

                    current.content.setAttribute('tabindex', 0);
                    current.content.classList.add('tab-content--selected');

                }


                // custom event: tabs choice
                this.$customEvent.detail.index = current.index;
                this.$customEvent.detail.trigger = current.trigger;
                this.$customEvent.detail.content = current.content;
                this.$el.dispatchEvent(this.$customEvent);

            }

        }

    }


    let active = null;


    /**
     * Tabs initialization
     * @param {object|string} variable - element for initializing tab
     */
    const init = (variable) => {

        let element = null,
            elements = BasicCore.variables(variable, '.js-tab');


        try {

            if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
            if (elements == null && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);

            elements.forEach((value) => {

                element = value;
                active = new Core(value);

            });

            return active;

        } catch (error) {

            console.error(`${BasicCore.logging['name']} Tabs init. \nMessage: ${error.message} \nElement: `, element);

        }

    }


    return {init};

})()


window.BasicTabs = BasicTabs;

export {BasicTabs};