/**
 * Basic UI: Slideout.
 */
const BasicSlideout = (() => {

	class Core {

	    constructor({trigger, slideout}) {
	        
	        this.$trigger = trigger || false;
			this.$slideout = slideout;

			this.$options = {
				className: {
					close: 	 'slideout-close',
					wrapper: 'slideout-wrapper',
					content: 'slideout-content',
					status: {
						show: 	'slideout--show',
						active: 'slideout--active',
					}
				}
			}

			this.variables();
			this.build();

	    }


		variables() {

			this.funcClickByArea = (event) => {

                let target = event.target;

                if (
					(target.getAttribute('data-slideout-close') !== null || target.closest(`.${this.$options.className.close}`) !== null) ||
					target.closest(`.${this.$options.className.content}`) == null && 
					target.closest('.slideout-trigger') == null					
				) {
					
					if (this.$trigger !== false) {
						
						this.$trigger.setAttribute('aria-expanded', false);
						this.$trigger.classList.remove(`${this.$options.className.status.active}`);
						
					}

					this.$slideout.classList.remove(`${this.$options.className.status.show}`);	
					
					document.body.classList.remove('body-slideout');
                    document.removeEventListener('click', this.funcClickByArea, false);

                }

            }

		}


		build() {

			if (this.$slideout.classList.contains('slideout-init') == false) {

				this.build_slideout();
				this.build_trigger();												

			}

		}


		build_slideout() {

			let btnClose = document.createElement('button');
			let content = document.createElement('div');
			

			btnClose.innerHTML = 'X';
			btnClose.classList.add(this.$options.className.close);
			btnClose.setAttribute('type', 'button');
			btnClose.setAttribute('title', 'Close slideout panel');
			btnClose.setAttribute('aria-label', 'Close slideout panel');


			content.innerHTML = btnClose.outerHTML;
			content.classList.add(this.$options.className.content);
			
			this.$slideout.removeAttribute('hidden');
			while (this.$slideout.firstChild) content.appendChild(this.$slideout.firstChild);


			this.$slideout.appendChild(content);
			this.$slideout.classList.add('slideout');
			this.$slideout.classList.add('slideout-init');

		}


		build_trigger() {

			if (this.$trigger !== false) {
															
				if (this.$trigger.getAttribute('id') == null) {
					
					this.$trigger.setAttribute('id', `trigger-slideout-${Array.from(document.querySelectorAll('.slideout-trigger')).indexOf(this.$trigger)}`);

				}
									
				this.$trigger.setAttribute('aria-haspopup', true);
				this.$trigger.setAttribute('aria-expanded', false);										
				this.$trigger.setAttribute('aria-controls', this.$slideout.getAttribute('id'));										
				this.$trigger.classList.add('slideout-trigger');
				this.$trigger.addEventListener('click', this.listener_visibility.bind(this), false);
				
				this.$slideout.setAttribute('aria-labelledby', this.$trigger.getAttribute('id'));
			
			}

		}


		listener_visibility(event) {

			let status = false;
			let target = event.target;


			document.body.classList.toggle('body-slideout');
			this.$slideout.classList.toggle(this.$options.className.status.show);						
			
			status = this.$slideout.classList.contains(this.$options.className.status.show);
						
			
			if (this.$trigger !== false) {

				this.$trigger.setAttribute('aria-expanded', status);
				this.$trigger.classList.toggle(this.$options.className.status.active);

			}			

			if (status == true) {

				document.addEventListener('click', this.funcClickByArea, false);

			}

		}


		method_show() {

			this.$slideout.classList.add(this.$options.className.status.show);
			document.addEventListener('click', this.funcClickByArea, false);

		}


		method_hide() {

			this.$slideout.classList.remove(this.$options.className.status.show);
			document.removeEventListener('click', this.funcClickByArea, false);

		}

	}


	let active = null;


	/**
	 * Slideout initialization
	 * @param {object|string} variable - element for initializing Slideout
	 */
	const init = (config) => {

		let options = config || {},	
			elements = BasicCore.variables(options.trigger, '.js-slideout-trigger');

			
		try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);

			elements.forEach((value) => {

				options.trigger = value;
				options.slideout = document.querySelector(`#${value.getAttribute('data-slideout-id')}`) || document.querySelector(options.content);

				active = new Core(options);

			});

			return active;

		} catch(error) {

			console.error(`${BasicCore.logging['name']} Slideout init. \nMessage: ${error.message} \nElement: `, options.trigger);

		}	

	};


	/**
	 * Method: Show a .slideout
	 * @param {(Object|string)} content - show a .slideout
	 */
	const show = (slideout) => {

		let options = {
			slideout: (typeof slideout == 'object') ? slideout : document.querySelector(slideout)
		};
				

		try {

			if (options.slideout == null) throw new Error(BasicCore.logging['error']['missing']);
			
			active = new Core(options);
			active.method_show();
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Slideout show. \nMessage: ${error.message} \nElement:`, slideout);

		}	

	};


	/**
	 * Method: Hide a .slideout
	 * @param {(Object|string)} slideout - hide a .slideout
	 */
	const hide = (slideout) => {

		let options = {
			slideout: (typeof slideout == 'object') ? slideout : document.querySelector(slideout)
		};
				

		try {

			if (options.slideout == null) throw new Error(BasicCore.logging['error']['missing']);
			
			active = new Core(options);
			active.method_hide();
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Slideout show. \nMessage: ${error.message} \nElement:`, slideout);

		}	

	};


	return { init, show, hide };

})()
	

window.BasicSlideout = BasicSlideout;


export  { BasicSlideout };