import anime from './anime.es.js'

const OthersAnimation = (() => {

    class Core {

        constructor(element) {

            this.$el = element;
            this.$coordTop = this.$el.getBoundingClientRect().top;
            
            let dataOptions = JSON.parse(this.$el.getAttribute('data-animation-options'));
            let dataStart = this.$el.getAttribute('data-animation-start');
            let dataParent = this.$el.getAttribute('data-animation-parent');

            this.$animeStart = dataStart || 'default';
            this.$options = {
                parent: document.querySelector(dataParent) || this.$el.parentElement,
                anime: Object.assign(
                    dataOptions,
                    { targets: this.$el }, 
                    { easing: dataOptions['easing'] || 'easeOutQuart' }, // https://codepen.io/kcjpop/pen/GvdQdX
                    { autoplay: (this.$animeStart !== 'default') ? false : true },
                )
            }
        }


        init() {

            this.$anime = anime(this.$options.anime);                       
            this.$el.classList.add('animation');
            this.$el.classList.add('animation-init');

            this.events();
            this.listener_scroll();

        }


        events() {

            window.addEventListener('scroll', this.listener_scroll.bind(this), false);
            
        }


        listener_scroll(event) {

            let parentClient = this.$options.parent.getBoundingClientRect();
            let parentCoord = Math.abs(parentClient.top) + (parentClient.height / 2); 


            if (
                this.$animeStart == 'visibility'                       &&
                this.$el.classList.contains('animation--end') == false &&
                window.outerHeight > parentCoord
            ) {
                
                this.$anime.play();
                this.$el.classList.add('animation--end');

            }

        }

    }
    

	let active = null;


	// init
	const init = () => {

        let elements = Array.from(document.querySelectorAll('.js-animation'));

        
        elements.forEach((value) => {

            active = new Core(value);
            active.init();

        })


        return active;

    };


    return { init };

})();


window.OthersAnimation = OthersAnimation;


export { OthersAnimation };