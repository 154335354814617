document.addEventListener( 'DOMContentLoaded', function() {
    const splide = new Splide( '.reputation-media', {
        breakpoints: {
            700: {
                perPage: 1,
                gap: 20,
                padding: { left: 0, right: 60 }
            },
            1000: {
                perPage: 2,
                gap: 20
            },
            1280: {
                perPage: 3,
                gap: 30
            }
        }

    } );
    splide.mount();
} );