const SectionTeam = (() => {

    class Core {

        constructor(el) {

            this.$el = el || false;

            if (this.$el !== false) this.init();

        }
    

        init() {

            this.$row = this.$el.querySelector('.team__row');
            this.$row.addEventListener('click', this.listener_click.bind(this), false);

        }


        listener_click(event) {

            let target = event.target,
                emp = target.closest('.team__emp') || false,
                isActive = this.$row.querySelector('.is-active') || false;


            if (emp !== false) {

                if (emp.classList.contains('is-active') == false && isActive !== false) {

                    isActive.classList.remove('is-active');

                }

                emp.classList.toggle('is-active');

            }

        }

    }

    
    let active = null;


    const init = () => {

        let element = document.querySelector('.team');

        try {

            active = new Core(element);
            return active;

        } catch (error) {

            console.error(`Secion Team: `, error);

        }

    }


    return { init };

})();


window.SectionTeam = SectionTeam;


export { SectionTeam };