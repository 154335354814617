import noUiSlider from "nouislider/dist/nouislider";
import { moonBlock } from "@verno.digital/moon-block";
import { eventsControl, useControl } from "./controls.js";

const disableAllControl = (listControl) => {
  for (const control of listControl) {
    useControl(control).disable();
  }
};

const initLine = (line) => {
  const multy = line.hasAttribute("data-multy");
  const listControl = line.querySelectorAll("[data-control]");

  for (const control of listControl) {
    control.addEventListener("click", () => {
      const { isActive, toggle, disable, active } = useControl(control);

      if (multy) {
        return toggle();
      }

      if (isActive()) {
        return disable();
      }

      disableAllControl(listControl);
      active();
    });
  }
};

const printJson = (block) => {
  const data = [];
  const inputData = block.querySelector('[data-id="backend"]');
  const listControl = block.querySelectorAll("[data-control]");

  for (const control of listControl) {
    const { isActive } = useControl(control);
    const { control: name, price, value } = control.dataset;

    if (isActive()) {
      data.push({
        name,
        price,
        value,
      });
    }
  }

  console.log(data);
  inputData.value = JSON.stringify(data);
};

moonBlock(".calculator", ({ block }) => {
  const slider = block.querySelector(".calculator__slider");
  const listItemLine = block.querySelectorAll(".calculator__line");
  const counterPrice = block.querySelector('[data-id="counter-price"]');
  const counterReviews = block.querySelector('[data-id="counter-reviews"]');

  const state = {
    price: 0,
  };

  const api = noUiSlider.create(slider, {
    start: 35,
    connect: "lower",
    range: {
      min: 35,
      max: 500,
    },
    step: 1,
  });

  listItemLine.forEach((line) => initLine(line));

  block.addEventListener(eventsControl.change, ({ target, detail }) => {
    const changeValue = Number(target.dataset.value);
    const changePrice = Number(target.dataset.price);
    const stateValue = Number(api.get());

    const newValue = detail.active ? changeValue + stateValue : Math.abs(changeValue - stateValue);

    api.set(newValue);
    counterReviews.innerHTML = newValue;

    const newPrice = detail.active ? changePrice + state.price : Math.abs(changePrice - state.price);

    state.price = newPrice;
    counterPrice.innerHTML = newPrice;

    printJson(block);
  });
});