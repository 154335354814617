export const eventsControl = {
    change: 'control-change'
}

const dispatch = {
    active(el) {
        el.dispatchEvent(
            new CustomEvent(eventsControl.change, {
                bubbles: true,
                detail: {
                    active: true
                }
            })
        )
    },
    disable(el) {
        el.dispatchEvent(
            new CustomEvent(eventsControl.change, {
                bubbles: true,
                detail: {
                    disable: true
                }
            })
        )
    }
}

export const useControl = (control) => {
    const isActive = () => {
        return control.classList.contains('calculator__button--active')
    }

    return {
        isActive: isActive,
        active() {
            if (isActive()) {
                return
            }

            control.classList.add('calculator__button--active')
            dispatch.active(control)
        },
        disable() {
            if (!isActive()) {
                return
            }

            control.classList.remove('calculator__button--active')
            dispatch.disable(control)
        },
        toggle() {
            const is = control.classList.toggle('calculator__button--active')

            is ? dispatch.active(control) : dispatch.disable(control)
        }
    }
}